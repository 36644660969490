@import "./loginscreen";
@import "./transactionscreen";
@import "./wallets";

.main-wrapper {
    display: flex;
    flex-wrap: wrap;
    //    background:$bgWhiteShade;
    background: #eee;
    min-height: 100vh;
    overflow: auto;
    padding-top: 65px;
    margin-top: -65px;
}

.main-layout {
    &.closed{
        width: 85%;
        // animation:.2s ease slideInLeft
        animation:.2s ease shrinkLayout;
    }
    &.opened{
        width: 100%;
        animation:.2s ease expandLayout;

    }
}

.content-wrapper {
    padding: 25px 25px;
    position: relative;

}



@media only screen and (max-width: 768px) {
    .main-layout {
        width: 100%;
        &.closed{
           width: 100%;
           animation: none;
        }
        &.opened{
            width: 100%;
           animation: none;
    
        }
    }
    
    .content-wrapper {
        padding: 25px 10px;
        height: calc((100vh - 105px) * 1);
        overflow: scroll;
        padding-bottom:100px;
    }
    .main-wrapper{
        height: 100px;
    }
}

@keyframes shrinkLayout {
    from{
        width: 100%;
    }
    to{
        width: 85%;
    }
}
@keyframes expandLayout {
    from{
        width: 85%;
    }
    to{
        width: 100%;
    }
}