* {
  padding: 0;
  margin: 0;
}

.circleAnimation {
  animation: 0.5s spinAnimation linear infinite;
  margin-left: 10px;
}

.row.animation-card {
  padding-bottom: 100px;
}

@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn {
  border-radius: 50px;
  padding: 10px 30px;
}
.btn svg {
  margin-right: 5px;
}
.btn.custom-btn-primary {
  background: #304e5e;
  color: white;
}
.btn.custom-btn-primary.outline {
  color: #304e5e;
  background: white;
  border: 1px solid #304e5e;
}
.btn.custom-btn-danger {
  background: tomato;
  color: white;
}
.btn.custom-btn-danger.outline {
  color: tomato;
  background: white;
  border: 1px solid tomato;
}
.btn.custom-btn-success {
  background: green;
  color: white;
}
.btn.custom-btn-success.outline {
  color: green;
  background: white;
  border: 1px solid green;
}
.btn.custom-btn-dark {
  background: #304e5e;
  color: white;
}
.btn.custom-btn-dark.outline {
  color: #304e5e;
  background: white;
  border: 1px solid #304e5e;
}
.btn.only-icon {
  padding: 5px 10px;
  margin-right: 3px;
}
.btn.only-icon svg {
  margin-right: 0;
}

.form-group {
  margin-bottom: 10px;
}
.form-group label {
  margin-bottom: 5px;
}
.form-group .form-control {
  padding: 0.75rem 0.5rem;
}
.form-group .form-control:focus {
  border-color: #304e5e;
  box-shadow: 0 0 0 0.25rem rgba(48, 78, 94, 0.2509803922);
}

@media only screen and (max-width: 768px) {
  .form-group {
    margin-bottom: 10px;
  }
  .form-group label {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .form-group .form-control {
    padding: 0.75rem 0.5rem;
  }
  .form-group .form-control:focus {
    border-color: #304e5e;
    box-shadow: 0 0 0 0.25rem rgba(48, 78, 94, 0.2509803922);
  }
}
.auth-form-group {
  margin: 30px 0;
  position: relative;
}
.auth-form-group .label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.375rem 0;
  margin: calc(0.375rem + 3px) 0;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: white;
  transition: transform 120ms ease-in;
  font-weight: normal;
  line-height: 1.2;
  color: #b2b2b2;
  z-index: 3;
  display: block;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.auth-form-group input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #b2b2b2;
  padding: 0.75rem 0.5rem;
  color: currentColor;
  background: transparent;
  border-radius: 0;
  z-index: 4;
  position: relative;
}
.auth-form-group input:not(:-moz-placeholder-shown) {
  border: 1px solid #b2b2b2;
}
.auth-form-group input:focus, .auth-form-group input:not(:placeholder-shown) {
  border: 1px solid #b2b2b2;
}
.auth-form-group input:not(:-moz-placeholder-shown) + .label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: #212121;
  background: white;
  font-size: 1.3rem;
  padding-left: 5px;
  padding-right: 5px;
  top: -3px;
  z-index: 5;
}
.auth-form-group input:focus + .label, .auth-form-group input:not(:placeholder-shown) + .label {
  transform: translate(0.25rem, -65%) scale(0.8);
  color: #212121;
  background: white;
  font-size: 1.3rem;
  padding-left: 5px;
  padding-right: 5px;
  top: -3px;
  z-index: 5;
}
.auth-form-group input:focus {
  outline: none;
  box-shadow: 0px 0px 0 1px #03A9F4;
}

@media only screen and (max-width: 768px) {
  .auth-form-group {
    position: relative;
  }
  .auth-form-group .label {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.375rem 0;
    margin: calc(0.375rem + 3px) 0;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: white;
    transition: transform 120ms ease-in;
    font-weight: normal;
    line-height: 1.2;
    color: #b2b2b2;
  }
  .auth-form-group input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #b2b2b2;
    padding: 0.5rem 0.5rem;
    color: currentColor;
    background: transparent;
    border-radius: 0;
  }
  .auth-form-group input:not(:-moz-placeholder-shown) {
    border: 1px solid #b2b2b2;
  }
  .auth-form-group input:focus, .auth-form-group input:not(:placeholder-shown) {
    border: 1px solid #b2b2b2;
  }
  .auth-form-group input:not(:-moz-placeholder-shown) + .label {
    transform: translate(0.25rem, -65%) scale(0.8);
    color: #212121;
    background: white;
    font-size: 1.3rem;
    padding-left: 5px;
    padding-right: 5px;
    top: -3px;
  }
  .auth-form-group input:focus + .label, .auth-form-group input:not(:placeholder-shown) + .label {
    transform: translate(0.25rem, -65%) scale(0.8);
    color: #212121;
    background: white;
    font-size: 1.3rem;
    padding-left: 5px;
    padding-right: 5px;
    top: -3px;
  }
  .auth-form-group input:focus {
    outline: none;
    box-shadow: 0px 0px 0 1px #03A9F4;
  }
}
.file-input .dropzone-container {
  border: 1px solid blue;
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
}
.file-input .dropzone-container .dropzone {
  cursor: pointer;
}
.file-input .dropzone-container .dropzone p {
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #afb8c5;
}
.file-input .file-list {
  margin-top: 25px;
}
.file-input .file-list .file-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.file-input .file-list .file-item .file-image .file-img {
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 10px;
  border: 1px solid #8895a8;
  margin-right: 10px;
  padding: 3px;
}
.file-input .file-list .file-item .file-content h3 {
  font-size: 0.8rem;
}
.file-input .file-list .file-item .file-content p {
  font-size: 0.6rem;
  margin-bottom: 0;
}

.left-side-bar {
  width: 15%;
  background: #f7f7f7;
  box-shadow: -10px 10px 17px #d9d9d9, 10px -10px 17px #e7e7e7;
  position: relative;
}
.left-side-bar.opened {
  display: none;
  width: 0%;
  opacity: 0;
  animation: 0.2s ease slideInRightCustom;
}
.left-side-bar.closed {
  display: block;
  width: 15%;
  opacity: 2;
  animation: 0.2s ease slideInLeftCustom;
}
.left-side-bar .logo {
  width: 100px;
  margin: 50px auto;
  display: block;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.left-side-bar .copyright {
  color: #afb8c5;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin: auto;
  width: 100%;
}
.left-side-bar .copyright p {
  color: #afb8c5;
  font-size: 0.7rem;
  font-weight: normal;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media only screen and (max-width: 768px) {
  .left-side-bar {
    width: 15%;
  }
  .left-side-bar .logo {
    width: 35px;
    margin: 25px auto;
  }
  .left-side-bar .copyright {
    display: none;
  }
}
@keyframes slideInLeftCustom {
  from {
    width: 0%;
  }
  to {
    width: 15%;
  }
}
@keyframes slideInRightCustom {
  from {
    width: 15%;
  }
  to {
    width: 0%;
  }
}
.top-nav-bar {
  height: 65px;
  padding: 10px 25px;
  background: #fbfbfd;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  position: relative;
  width: 100%;
}
.top-nav-bar .toggle-button.closed {
  margin-left: 15%;
}
.top-nav-bar .toggle-button.opened {
  margin-left: 0%;
}
.top-nav-bar .toggle-button button {
  background: none;
  font-size: 1.5rem;
  border: none;
}
.top-nav-bar .profile {
  position: relative;
  min-width: 100px;
  max-width: 200px;
}
.top-nav-bar .profile .profile-option {
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  justify-content: flex-end;
}
.top-nav-bar .profile .profile-option img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}
.top-nav-bar .profile .profile-option .name-content {
  margin-right: 15px;
}
.top-nav-bar .profile .profile-option .name-content h3 {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: bold;
}
.top-nav-bar .profile .profile-option .name-content p {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #8895a8;
}
.top-nav-bar .profile .profile-drop-down {
  position: absolute;
  background-color: white;
  width: 100%;
  box-shadow: 0px 2px 3px #eaeaea;
  padding-bottom: 5px;
  border-bottom: 5px solid #afb8c5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 0.5s ease all;
  margin-top: 10px;
  transform: translateY(-100px);
  opacity: 0;
  z-index: -1;
}
.top-nav-bar .profile .profile-drop-down.show {
  transform: translateY(0);
  opacity: 1;
  z-index: 1;
}
.top-nav-bar .profile .profile-drop-down .profile-menu .menu-item .menu-content {
  padding: 5px 10px;
}
.top-nav-bar .profile .profile-drop-down .profile-menu .menu-item .menu-content .icon svg {
  font-size: 0.8rem;
}
.top-nav-bar .profile .profile-drop-down .profile-menu .menu-item .menu-content .menu-title h3 {
  font-size: 0.9rem;
}
.top-nav-bar .notification {
  position: relative;
}
.top-nav-bar .notification .notification-option {
  text-align: right;
  padding-right: 20px;
}
.top-nav-bar .notification .notification-option .notification-icon {
  cursor: pointer;
  position: relative;
}
.top-nav-bar .notification .notification-option .notification-icon svg {
  font-size: 1.5rem;
}
.top-nav-bar .notification .notification-option .notification-icon span {
  position: absolute;
  height: 5px;
  width: 5px;
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  animation: pulse 2s infinite;
  border-radius: 50%;
}
.top-nav-bar .notification .notification-drop-down {
  position: absolute;
  background-color: white;
  width: 300px;
  right: 0;
  box-shadow: 0px 2px 3px #eaeaea;
  padding-bottom: 5px;
  border-bottom: 5px solid #afb8c5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: 0.5s ease all;
  margin-top: 20px;
  transform: translateY(-100%);
  opacity: 0;
  z-index: -10;
  min-height: 100px;
}
.top-nav-bar .notification .notification-drop-down.show {
  transform: translateY(0);
  opacity: 1;
  z-index: 1;
}
.top-nav-bar .notification .notification-drop-down .no-notifications {
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.top-nav-bar .notification .notification-drop-down .no-notifications span {
  font-size: 0.8rem;
  color: #8895a8;
}
.top-nav-bar .settings {
  position: relative;
}
.top-nav-bar .settings .settings-option {
  text-align: right;
  padding-right: 20px;
}
.top-nav-bar .settings .settings-option .settings-icon {
  cursor: pointer;
  position: relative;
}
.top-nav-bar .settings .settings-option .settings-icon svg {
  font-size: 1.5rem;
}
.top-nav-bar .settings .settings-option .settings-icon span {
  position: absolute;
  height: 5px;
  width: 5px;
  box-shadow: 0 0 0 0 rgb(0, 0, 0);
  transform: scale(1);
  animation: pulse 2s infinite;
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.menu-item {
  width: 90%;
  margin: 15px auto;
  border-radius: 25px;
  color: #afb8c5;
  cursor: pointer;
  transition: 0.2s ease all;
}
.menu-item.mobile {
  margin: 5px auto;
}
.menu-item .menu-content {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  width: 90%;
  margin: auto;
}
.menu-item .icon {
  margin-right: 7px;
}
.menu-item .icon svg {
  font-size: 1.2rem;
}
.menu-item .menu-title h3 {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 0;
}
.menu-item:hover {
  color: #8895a8;
}
.menu-item.active {
  color: #304e5e;
  background: #e1ebf0;
}

.notification-item-wrapper {
  padding: 5px 10px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.notification-item-wrapper:hover {
  background: #f7f8fc;
}
.notification-item-wrapper .notification-content-wrapper {
  display: flex;
  align-items: center;
}
.notification-item-wrapper .notification-content-wrapper .notification-icon {
  width: 25%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification-item-wrapper .notification-content-wrapper .notification-icon span {
  background: black;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}
.notification-item-wrapper .notification-content-wrapper .notification-icon span svg {
  font-size: 2rem;
  color: white;
}
.notification-item-wrapper .notification-content-wrapper .notification-content {
  width: 75%;
}
.notification-item-wrapper .notification-content-wrapper .notification-content h3 {
  font-size: 1rem;
  margin-bottom: 0;
}
.notification-item-wrapper .notification-content-wrapper .notification-content p {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.side-modal {
  position: absolute;
  top: -65px;
  right: 0;
  width: 0%;
  background: white;
  height: calc((100vh - 0px) * 1);
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 1px 2px 3px #d9d9d9;
  z-index: 10;
  opacity: 0;
  transform: translateX(100%);
  transition: 0.5s ease all;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.side-modal.show {
  opacity: 1;
  transform: translateX(0);
  width: 60%;
}
.side-modal .sidebar-heading {
  padding: 15px 25px;
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-modal .sidebar-heading .sidebar-control {
  cursor: pointer;
}
.side-modal .sidebar-heading .sidebar-control svg {
  font-size: 1.7rem;
  color: #8895a8;
}
.side-modal .sidebar-heading .sidebar-buttons {
  width: 100%;
  text-align: right;
}
.side-modal .sidebar-heading .sidebar-buttons button {
  padding: 3px 15px;
  border-radius: 15px;
}
.side-modal .sidebar-body {
  padding: 15px 25px;
}
.side-modal .sidebar-body .sidebar-title {
  font-size: 1.2rem;
  font-weight: bold;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 10px 0;
}
.overlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3294117647);
  position: absolute;
  z-index: 4;
  top: 0;
}

@media only screen and (max-width: 768px) {
  .side-modal {
    position: absolute;
    top: -75px;
    right: 0;
    width: 0%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(100%);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .side-modal.show {
    opacity: 1;
    transform: translateX(0);
    width: 100%;
    position: fixed;
    top: 0;
  }
  .side-modal .sidebar-body {
    height: 720px;
    overflow: scroll;
    padding-bottom: 150px;
  }
  .side-modal .sidebar-body .sidebar-title {
    font-size: 1rem;
    padding: 10px 0;
  }
}
.main-page {
  background: white;
  border-radius: 20px;
  min-height: 650px;
}
.main-page .main-title {
  padding: 15px 25px;
  border-bottom: 1px solid #000;
}
.main-page .main-title h3 {
  font-size: 1.3rem;
  margin-bottom: 0;
}
.main-page .main-body {
  padding: 10px 25px;
}

.exp-container .swal2-modal {
  --animate-duration: .5s;
  padding-left: 70px !important;
  position: relative;
}
.exp-container .swal2-modal .exp-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -15px;
  left: 10px;
}
.exp-container .swal2-modal .exp-icon .swal2-icon-content {
  font-size: 2rem;
}
.exp-container .swal2-modal .exp-title {
  text-align: left;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
}
.exp-container .swal2-modal .exp-htmlContainer {
  text-align: left;
  font-size: 0.8rem;
  font-weight: normal;
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
}
.exp-container .swal2-modal .exp-actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
}
.exp-container .swal2-modal .exp-actions button {
  font-size: 0.7rem;
  padding: 5px 10px;
  margin-right: 5px;
}

@media only screen and (max-width: 768px) {
  .main-page {
    border-radius: 20px;
    height: auto;
    min-height: unset;
  }
  .main-page .main-title {
    padding: 10px 25px;
  }
  .main-page .main-title h3 {
    font-size: 1.3rem;
  }
  .main-page .main-body {
    padding: 10px 25px;
  }
}
.custom-table-wrapper .MuiPaper-root {
  background: white;
  box-shadow: none;
}
.custom-table-wrapper .MuiTablePagination-selectLabel,
.custom-table-wrapper .MuiTablePagination-displayedRows {
  margin-bottom: 0;
}
.custom-table-wrapper .MuiIconButton-sizeMedium:hover {
  color: #304e5e;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
  min-width: 160px;
  padding: 10px 20px;
  margin-top: 15px;
}

.MuiInput-underline input :after {
  border-color: #304e5e !important;
}

.MuiTableCell-root.MuiTableCell-head span {
  display: block;
}
.MuiTableCell-root.MuiTableCell-head span button {
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .custom-table-wrapper .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiToolbar-root {
    padding-left: 0;
    padding-right: 0;
  }
  .custom-table-wrapper .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded .MuiToolbar-root div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .custom-table-wrapper .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded div:nth-child(3) table tbody tr td {
    padding: 5px;
  }
  .custom-table-wrapper .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded div:nth-child(3) table tbody tr td div {
    font-size: 11px;
    text-align: left;
    font-weight: normal;
  }
  .custom-table-wrapper button.btn.btn-sm.custom-btn-dark.ms-2.false {
    padding: 6px 5px;
    font-size: 0.7rem;
  }
  .custom-table-wrapper .MuiTable-root .MuiTableFooter-root .MuiTableCell-root.MuiTableCell-footer.MuiTableCell-sizeMedium {
    padding: 0;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
    min-width: 160px;
    padding: 0 0;
    padding-top: 10px;
    margin-top: 15px;
  }
}
.amount.income {
  color: green;
}
.amount.expense {
  color: red;
}
.amount.neutral {
  color: #304e5e;
}

.label-chip {
  padding: 4px 8px;
  border-radius: 50px;
  margin-right: 2px;
  background-color: rgba(0, 0, 0, 0.0784313725);
}
.label-chip.active {
  background-color: #304e5e;
}

.category-icon .icon-color {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.category-icon .icon-color svg {
  color: white;
  font-size: 1rem;
}

.category-table {
  display: flex;
  align-items: center;
}
.category-table b {
  margin-left: 10px;
}

.category-selector .controller {
  display: flex;
  align-items: center;
}
.category-selector .controller .control {
  cursor: pointer;
}
.category-selector .controller .control svg {
  font-size: 1.5rem;
  opacity: 0.5;
}
.category-selector .controller .control:hover svg {
  opacity: 1;
}
.category-selector .controller .category-color-picker {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  line-height: 0;
  scroll-behavior: smooth;
}
.category-selector .controller .category-color-picker .color-circle {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-align: center;
}
.category-selector .controller .category-color-picker .color-circle .selected {
  height: 10px;
  width: 10px;
  background: white;
  position: absolute;
  border-radius: 50%;
  left: 10px;
  animation: 0.5s ease selectColor;
}
.category-selector .controller .category-icon-picker {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  scroll-behavior: smooth;
}
.category-selector .controller .category-icon-picker .icon-circle {
  border-radius: 50px;
  margin-right: 10px;
  padding: 8px;
  animation: 2s ease all;
}
.category-selector .controller .category-icon-picker .icon-circle.selected {
  background-color: #4c6e81;
  animation: 0.5s ease selectIcon;
}
.category-selector .controller .category-icon-picker .icon-circle.selected svg {
  color: #fff;
}
.category-selector .controller .category-icon-picker .icon-circle svg {
  font-size: 1.3rem;
  height: 30px;
  width: 30px;
  color: #000;
}
.category-selector .controller .category-icon-picker .friend-picker {
  background: rgba(0, 0, 0, 0.0784313725);
  border: 2px solid rgba(0, 0, 0, 0.0784313725);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 25px;
  border-radius: 50px;
  opacity: 0.6;
  width: -moz-min-content;
  width: min-content;
}
.category-selector .controller .category-icon-picker .friend-picker img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.category-selector .controller .category-icon-picker .friend-picker span.title {
  margin-left: 5px;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.category-selector .controller .category-icon-picker .friend-picker.active {
  background: white;
  border: 2px solid black;
  opacity: 1;
}
.category-selector .controller .category-picker {
  background: rgba(0, 0, 0, 0.0784313725);
  border: 2px solid rgba(0, 0, 0, 0.0784313725);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
  border-radius: 50px;
  opacity: 0.6;
}
.category-selector .controller .category-picker span.title {
  margin-left: 5px;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.category-selector .controller .category-picker.active {
  background: white;
  border: 2px solid black;
  opacity: 1;
}

.type-picker {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  margin-top: 5px;
}
.type-picker .type-pick {
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.0784313725);
  margin-right: 10px;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  opacity: 0.4;
}
.type-picker .type-pick:hover {
  opacity: 1;
}
.type-picker .type-pick span {
  position: absolute;
  top: -7px;
  display: none;
  opacity: 0;
  right: 0;
}
.type-picker .type-pick span svg {
  background: green;
  border-radius: 50%;
  font-size: 1.5rem;
  color: white;
}
.type-picker .type-pick.expense span svg {
  background: tomato;
}
.type-picker .type-pick.active {
  opacity: 1;
}
.type-picker .type-pick.active span {
  display: block;
  opacity: 1;
}

@keyframes selectColor {
  from {
    width: 30px;
    height: 30px;
    left: 0px;
  }
  to {
    width: 10px;
    height: 10px;
    left: 10px;
  }
}
@keyframes selectIcon {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media only screen and (max-width: 768px) {
  .category-icon .icon-color {
    height: 25px;
    width: 25px;
  }
  .category-table {
    display: flex;
    align-items: center;
  }
  .category-table b {
    margin-left: 10px;
  }
  .category-selector {
    margin-top: 15px;
  }
  .category-selector .controller .category-picker {
    border: 1px solid rgba(0, 0, 0, 0.0784313725);
    padding: 5px 7px;
  }
  .category-selector .controller .category-picker span.title {
    font-size: 0.7rem;
  }
  .category-selector .controller .category-picker.active {
    border: 1px solid black;
  }
  .type-picker {
    margin-bottom: 15px;
  }
  .type-picker .type-pick {
    padding: 7px 12px;
    font-size: 0.8rem;
  }
  .type-picker .type-pick span {
    top: -7px;
    right: -3px;
  }
  .type-picker .type-pick span svg {
    font-size: 1rem;
  }
}
.mobile-navbar {
  min-height: 75px;
  background: #fbfbfd;
  box-shadow: -10px 10px 17px #d9d9d9, 10px -10px 17px #e7e7e7;
  transition: 0.5s ease all;
  position: relative;
}
.mobile-navbar .navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  height: 75px;
}
.mobile-navbar .navbar-wrapper .logo img {
  height: 50px;
}
.mobile-navbar .navbar-wrapper .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mobile-navbar .navbar-wrapper .right .avatar img {
  height: 35px;
  border-radius: 50%;
  margin-right: 15px;
}
.mobile-navbar .navbar-wrapper .right .toggler-bar {
  margin-right: 15px;
}
.mobile-navbar .navbar-wrapper .right .toggler-bar svg {
  font-size: 1.5rem;
  color: #304e5e;
}
.mobile-navbar .menu-bar {
  width: 100%;
  padding: 25px 0;
  border-top: 0.1px solid #304e5e;
}
.mobile-navbar .menu-bar.show {
  animation: 0.5s ease slideIn;
}

.main-overlay {
  background: rgba(0, 0, 0, 0.2196078431);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}

@keyframes slideIn {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}
#preloader {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
}
#preloader section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
#preloader section .content {
  position: relative;
}
#preloader section .content div {
  position: absolute;
  margin-top: 25px;
}
#preloader section .content h2 {
  color: #fff;
  font-size: 5em;
  position: absolute;
  transform: translate(-50%, -50%);
}
#preloader section .content h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #304e5e;
}
#preloader section .content h2:nth-child(2) {
  color: #304e5e;
  animation: animate 4s ease-in-out infinite;
}
#preloader section h3 {
  font-size: 1rem;
  margin-top: 50px;
}

@keyframes animate {
  0%, 100% {
    -webkit-clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
  }
}
.auth-screen-container {
  display: flex;
}
.auth-screen-container .left-container {
  width: 50%;
  background: #304e5e;
  background: linear-gradient(45deg, #304e5e 0%, #4c6e81 100%);
  position: relative;
  padding: 50px 50px;
  min-height: 100vh;
}
.auth-screen-container .left-container .logo-container {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  font-family: sans-serif;
  -webkit-text-fill-color: #304e5e;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.auth-screen-container .left-container .banner-content-container {
  width: 60%;
  padding-top: 15px;
}
.auth-screen-container .left-container .banner-content-container p {
  font-size: 1rem;
  color: white;
}
.auth-screen-container .left-container .image-container {
  position: absolute;
  right: -5%;
  z-index: 15;
}
.auth-screen-container .left-container .image-container .lf-player-container {
  width: 600px;
}
.auth-screen-container .right-container {
  z-index: 2;
  width: 53%;
  margin-left: -3%;
  background: white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding: 50px;
  position: relative;
}
.auth-screen-container .right-container .terms-link-container {
  display: flex;
  justify-content: flex-end;
}
.auth-screen-container .right-container .terms-link-container a, .auth-screen-container .right-container .terms-link-container span {
  color: #b2b2b2;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
}
.auth-screen-container .right-container .form-container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 0 25px;
  position: relative;
}
.auth-screen-container .right-container .form-container.login-page {
  padding-top: 150px;
}
.auth-screen-container .right-container .form-container .title {
  font-size: 2rem;
  margin-bottom: 50px;
}
.auth-screen-container .right-container .form-container .handlers-container {
  text-align: center;
}
.auth-screen-container .right-container .form-container .handlers-container .middle-text {
  margin: 15px auto;
}
.auth-screen-container .right-container .form-container .handlers-container .middle-text span {
  color: #b2b2b2;
}
.auth-screen-container .right-container .form-container .footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
}
.auth-screen-container .right-container .form-container .footer-container p {
  margin-bottom: 0.2rem;
}
.auth-screen-container .right-container .form-container .footer-container p a, .auth-screen-container .right-container .form-container .footer-container p span {
  cursor: pointer;
  color: #304e5e;
  text-decoration: none;
  transition: 0.2s ease all;
}
.auth-screen-container .right-container .form-container .footer-container p a:hover, .auth-screen-container .right-container .form-container .footer-container p span:hover {
  color: #4c6e81;
}

@media only screen and (max-width: 768px) {
  .auth-screen-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .auth-screen-container .left-container {
    width: 100%;
    position: fixed;
    padding: 10px 20px;
    padding-top: 25px;
    top: 0;
    z-index: 3;
    height: unset;
    min-height: unset;
  }
  .auth-screen-container .left-container .banner-content-container {
    width: 75%;
    padding-top: 15px;
  }
  .auth-screen-container .left-container .banner-content-container p {
    font-size: 0.9rem;
    color: white;
  }
  .auth-screen-container .left-container .image-container {
    display: none;
  }
  .auth-screen-container .right-container {
    width: 100%;
    padding: 10px 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    margin-top: 250px;
  }
  .auth-screen-container .right-container .terms-link-container {
    display: none;
  }
  .auth-screen-container .right-container .form-container {
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 0 25px;
    padding-bottom: 100px;
  }
  .auth-screen-container .right-container .form-container.login-page {
    padding-top: 25px;
  }
  .auth-screen-container .right-container .form-container .title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .auth-screen-container .right-container .form-container .handlers-container {
    text-align: center;
  }
  .auth-screen-container .right-container .form-container .handlers-container .middle-text {
    margin: 15px auto;
  }
  .auth-screen-container .right-container .form-container .handlers-container .middle-text span {
    color: #b2b2b2;
  }
  .auth-screen-container .right-container .form-container .footer-container {
    position: relative;
    margin-top: 25px;
    padding-bottom: 50px;
  }
  .vh-100.auth-layout {
    overflow: scroll;
  }
}
.transaction-tab {
  text-align: center;
}
.transaction-tab .toggle {
  background: rgba(0, 0, 0, 0.0784313725);
  width: -moz-max-content;
  width: max-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 50px;
}
.transaction-tab .toggle .button {
  padding: 7px 15px;
  border-radius: 50px;
  cursor: pointer;
}
.transaction-tab .toggle .button.active {
  background-color: #304e5e;
  color: white;
}

@media only screen and (max-width: 768px) {
  .transaction-tab .toggle {
    padding: 7px;
  }
  .transaction-tab .toggle .button {
    padding: 5px 12px;
    font-size: 0.8rem;
  }
}
.warning-buttons button {
  margin-right: 10px;
}

.verified-icon {
  color: #304e5e;
  font-size: 1rem;
  margin-left: 2px;
}

.main-wrapper {
  display: flex;
  flex-wrap: wrap;
  background: #eee;
  min-height: 100vh;
  overflow: auto;
  padding-top: 65px;
  margin-top: -65px;
}

.main-layout.closed {
  width: 85%;
  animation: 0.2s ease shrinkLayout;
}
.main-layout.opened {
  width: 100%;
  animation: 0.2s ease expandLayout;
}

.content-wrapper {
  padding: 25px 25px;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .main-layout {
    width: 100%;
  }
  .main-layout.closed {
    width: 100%;
    animation: none;
  }
  .main-layout.opened {
    width: 100%;
    animation: none;
  }
  .content-wrapper {
    padding: 25px 10px;
    height: calc((100vh - 105px) * 1);
    overflow: scroll;
    padding-bottom: 100px;
  }
  .main-wrapper {
    height: 100px;
  }
}
@keyframes shrinkLayout {
  from {
    width: 100%;
  }
  to {
    width: 85%;
  }
}
@keyframes expandLayout {
  from {
    width: 85%;
  }
  to {
    width: 100%;
  }
}/*# sourceMappingURL=style.css.map */