.auth-form-group {
    margin:30px 0;
    position: relative;
   .label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(.5rem * 0.75) 0;
      margin: calc(.5rem * 0.75 + 3px) 0;
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: white;
      transition: transform 120ms ease-in;
      font-weight: normal;
      line-height: 1.2;
      color:$lightGrey;
      z-index: 3;
      display: block;
      user-select: none;
    }
    input {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border:none;
      border-bottom: 1px solid $lightGrey;
      padding: calc(.5rem * 1.5) .5rem;
      color: currentColor;
      background: transparent;
      border-radius: 0;
      z-index: 4;
      position: relative;
      &:focus,
      &:not(:placeholder-shown) {
        border: 1px solid $lightGrey;
        & + .label {
          transform: translate(.25rem, -65%) scale(.8);
          color: #212121;
          background:white;
          font-size: 1.3rem;
          padding-left: 5px;
          padding-right: 5px;
          top:-3px;
          z-index: 5;
        }
      }
      &:focus{
        outline:none;
        box-shadow: 0px 0px 0 1px #03A9F4;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .auth-form-group {
      position: relative;
     .label {
        position: absolute;
        left: 0;
        top: 0;
        padding: calc(.5rem * 0.75) 0;
        margin: calc(.5rem * 0.75 + 3px) 0;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        background: white;
        transition: transform 120ms ease-in;
        font-weight: normal;
        line-height: 1.2;
        color:$lightGrey;
      }
      input {
        box-sizing: border-box;
        display: block;
        width: 100%;
        border:none;
        border-bottom: 1px solid $lightGrey;
        padding: calc(.5rem * 1) .5rem;
        color: currentColor;
        background: transparent;
        border-radius: 0;
        
        &:focus,
        &:not(:placeholder-shown) {
          border: 1px solid $lightGrey;
          & + .label {
            transform: translate(.25rem, -65%) scale(.8);
            color: #212121;
            background:white;
            font-size: 1.3rem;
            padding-left: 5px;
            padding-right: 5px;
            top:-3px;
          }
        }
        &:focus{
          outline:none;
          box-shadow: 0px 0px 0 1px #03A9F4;
        }
      }
    }
  }