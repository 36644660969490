.custom-table-wrapper {
    .MuiPaper-root {
        background: white;
        box-shadow: none;
    }

    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
        margin-bottom: 0;
    }

    .MuiIconButton-sizeMedium {
        &:hover {
            color: $primaryDark;
        }
    }

    .MuiInputBase-input {
        &.MuiInput-input {}
    }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
    min-width: 160px;
    padding: 10px 20px;
    margin-top: 15px;
}

.MuiInput-underline input {

    // background: #000;
    :after {
        border-color: $primaryDark !important;
    }
}

.MuiTableCell-root.MuiTableCell-head {
    span {
        display: block;

        button {
            padding: 0;
        }
    }
}


// MuiTableCell-root MuiTableCell-head MuiTableCell-sizeMedium


@media only screen and (max-width: 768px) {
    .custom-table-wrapper {
        .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
            .MuiToolbar-root {
                padding-left: 0;
                padding-right: 0;

                div:nth-child(2) {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                }
            }

            div:nth-child(3) {
                table {
                    tbody {
                        tr {
                            td {
                                padding: 5px;
                                div {
                                    font-size: 11px;
                                    text-align: left;
                                    font-weight: normal;
                                }
                            }
                        }
                    }
                }
            }
        }

        button.btn.btn-sm.custom-btn-dark.ms-2.false {
            padding: 6px 5px;
            font-size: 0.7rem;
        }

        .MuiTable-root{
            .MuiTableFooter-root{
                .MuiTableCell-root.MuiTableCell-footer.MuiTableCell-sizeMedium{
                    padding: 0;
                }
            }
        }

    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.MuiPopover-paper.css-ubpweo-MuiPaper-root-MuiPopover-paper {
        min-width: 160px;
        padding: 0 0;
        padding-top: 10px;
        margin-top: 15px;
    }
}