.side-modal{
    position: absolute;
    top: -65px;
    right: 0;
    width: 0%;
    background: white;
    // height: 100vh;
    // margin-top:-65px;
    height: calc((100vh - 0px) * 1);
    overflow-y: auto;
    overflow-x: hidden;
    // padding: 50px 25px;
    box-shadow: $bgPrimaryWhiteShadowRight;
    z-index: 10;
    opacity: 0;
    transform: translateX(100%);
    transition: .5s ease all;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    &.show{
        opacity: 1;
        transform: translateX(0);
        width: 60%;
    }

    .sidebar-heading{
        padding:15px 25px;
        border-bottom: 1px solid grey;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sidebar-control{
            cursor: pointer;
            svg{
                font-size:1.7rem;
                color: $textBlackShade;
            }
        }
        .sidebar-buttons{
            width: 100%;
            text-align: right;
            button{
                padding: 3px 15px;
                border-radius: 15px;
            }
        }
    }

    .sidebar-body{
        padding:15px 25px;
        .sidebar-title{
            font-size: 1.2rem;
            font-weight: bold;
            user-select: none;
            padding: 10px 0;
        }
        .sidebar-content{
        }
    }
}


.overlay{
    height: 100%;
    width: 100%;
    background: #00000054;
    position: absolute;
    z-index: 4;
    top: 0;
    // left: 15%;
}


@media only screen and (max-width: 768px) {

.side-modal{
    position: absolute;
    top: -75px;
    right: 0;
    width: 0%;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(100%);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    &.show{
        opacity: 1;
        transform: translateX(0);
        width: 100%;
        position: fixed;
    top: 0;
    }


    .sidebar-body{
        height: 720px;
        overflow: scroll;
        padding-bottom: 150px;
        .sidebar-title{
            font-size: 1rem;
            padding: 10px 0;
        }
    }
}
}