#preloader {

    position: absolute;
    height: 100%;
    width: 100%;
    background: white;

    section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .content {
            position: relative;

            div {
                position: absolute;
                margin-top: 25px;
            }

            h2 {
                color: #fff;
                font-size: 5em;
                position: absolute;
                transform: translate(-50%, -50%);

                &:nth-child(1) {
                    color: transparent;
                    -webkit-text-stroke: 2px $primaryDark;
                }

                &:nth-child(2) {
                    color: $primaryDark;
                    animation: animate 4s ease-in-out infinite;
                }
            }
        }

        h3 {
            font-size: 1rem;
            margin-top: 50px;
        }
    }




}

@keyframes animate {

    0%,
    100% {
        clip-path: polygon(0% 45%,
                16% 44%,
                33% 50%,
                54% 60%,
                70% 61%,
                84% 59%,
                100% 52%,
                100% 100%,
                0% 100%);
    }

    50% {
        clip-path: polygon(0% 60%,
                15% 65%,
                34% 66%,
                51% 62%,
                67% 50%,
                84% 45%,
                100% 46%,
                100% 100%,
                0% 100%);
    }
}