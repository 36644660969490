.auth-screen-container{
    display: flex;
    // min-height: 100vh;
    // height:100%;
  .left-container{
    width:50%;
    background:$primaryBG;
    background:$primaryGradient;
    position:relative;
    padding:50px 50px;
    min-height: 100vh;
    .logo-container{
        font-size:2rem;
        font-weight:bold;
        color:white;
        font-family:sans-serif;
        -webkit-text-fill-color: $primaryDark;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
    }
    .banner-content-container{
      width:60%;
      padding-top:15px;
      p{
        font-size:1rem;
        color:white;
      }
    }
    .image-container{
      position: absolute;
      right: -5%;
      z-index: 15;
       .lf-player-container{
        width:600px;
       }
    }
  }
  .right-container{
    z-index: 2;
    width:53%;
    margin-left:-3%;
    background:white;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding:50px;
    position:relative;  
    .terms-link-container{
      display:flex;
      justify-content:flex-end;
      a,span{
        color:#b2b2b2;
        text-decoration:none;
        font-size:1rem;
        cursor: pointer;
      }
    }
 
    .form-container{
      width: 80%;
      height:100%;
      margin: 0 auto;
      padding:0 25px;
      position:relative;

      &.login-page{
        padding-top:150px;
      }

      .title{
        font-size:2rem;
        margin-bottom:50px;
      }
   
      .handlers-container{
        text-align:center;
        .middle-text{
          margin:15px auto;
          span{
            color:#b2b2b2;
          }
        }
      }

      .footer-container{
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        left: 0;
        p{
          margin-bottom:0.2rem;
          a,span{
            cursor:pointer;
            color:$primaryDark;
            text-decoration:none;
            transition:0.2s ease all;
            &:hover{
              color:$primaryDarkShade;
            }
          }
        }
      }
      
    }
  }
}

@media only screen and (max-width: 768px) {
  .auth-screen-container{
    display: flex;
    flex-direction: column;
    width: 100%;
  .left-container{
    width:100%;
    position:fixed;
    padding:10px 20px;
    padding-top:25px;
    top: 0;
    z-index: 3;
    height: unset;
    min-height: unset;
    .banner-content-container{
      width:75%;
      padding-top:15px;
      p{
        font-size:0.9rem;
        color:white;
      }
    }
    .image-container{
      display: none;
    }
  }
  .right-container{
    width:100%;
    padding:10px 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left:0;
    margin-top: 250px;
    .terms-link-container{
      display: none
    }
 
    .form-container{
      width: 90%;
      height:auto;
      margin: 0 auto;
      padding:0 25px;
      padding-bottom: 100px;

      &.login-page{
        padding-top:25px;
      }

      .title{
        font-size:1.5rem;
        margin-bottom:15px;
      }
   
      .handlers-container{
        text-align:center;
        .middle-text{
          margin:15px auto;
          span{
            color:#b2b2b2;
          }
        }
      }

      .footer-container{
        position: relative;
        margin-top: 25px;
        padding-bottom:50px;

      }
      
    }
  }
}

.vh-100{
  &.auth-layout{
    overflow: scroll;
  }
}
}