.amount{
    &.income{
        color:green;
    }
    &.expense{
        color:red;
    }
    &.neutral{
        color:$primaryDark
    }
}