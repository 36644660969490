.transaction-tab{
    text-align: center;

    .toggle{
        background:$lightBG;
        width: max-content;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        border-radius: 50px;
        .button{
            padding: 7px 15px;
            border-radius: 50px;
            cursor: pointer;
            &.active{
                background-color: $primaryDark;
                color:white;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .transaction-tab{
    
        .toggle{
            padding: 7px;
            .button{
                padding: 5px 12px;
                font-size:0.8rem;
            }
        }
    }

    
}

.warning-buttons{
    button{
        margin-right:10px;
    }
}