.main-page {
    background: white;
    border-radius: 20px;
    min-height: 650px;

    .main-title {
        padding: 15px 25px;
        border-bottom: 1px solid #000;

        h3 {
            font-size: 1.3rem;
            margin-bottom: 0;
        }
    }

    .main-body {
        padding: 10px 25px;
    }
}

.exp-container {
    .swal2-modal {
        --animate-duration: .5s;
        padding-left: 70px !important;
        position: relative;

        .exp-icon {
            position: absolute;
            width: 40px;
            height: 40px;
            top: -15px;
            left: 10px;

            .swal2-icon-content {
                font-size: 2rem;
            }
        }

        .exp-title {
            text-align: left;
            font-size: 1.1rem;
            font-weight: bold;
            padding: 0;
            margin: 0;
            padding-bottom: 5px;
        }

        .exp-htmlContainer {

            text-align: left;

            font-size: 0.8rem;
            font-weight: normal;
            padding: 0;
            margin: 0;
            padding-bottom: 5px;
        }

        .exp-actions {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            margin: 0;
            padding: 0;

            button {
                font-size: 0.7rem;
                padding: 5px 10px;
                margin-right: 5px;
            }
        }
    }
}



@media only screen and (max-width: 768px) {

.main-page {
    border-radius: 20px;
    height: auto;
    min-height: unset;
    .main-title {
        padding: 10px 25px;
        h3 {
            font-size: 1.3rem;
        }
    }
    .main-body {
        padding: 10px 25px;
    }
}
}