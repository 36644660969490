.circleAnimation{
    animation:.5s spinAnimation linear infinite; 
    margin-left:10px;
}
.row.animation-card {
    padding-bottom: 100px;
}

@keyframes spinAnimation {
    0%{
        transform:rotate(0deg)
    }
    100%{
        transform:rotate(360deg)
    }
}