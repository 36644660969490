.notification-item-wrapper {
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: $bgWhiteShade;
    }

    .notification-content-wrapper {
        display: flex;
        align-items: center;

        .notification-icon {
            width: 25%;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                background: black;
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;

                svg {
                    font-size: 2rem;
                    color: white;
                }
            }
        }

        .notification-content {
            width: 75%;

            h3 {
                font-size: 1rem;
                margin-bottom: 0;
            }

            p {
                font-size: 0.8rem;
                margin-bottom: 0;
            }
        }
    }
}