.mobile-navbar{
    min-height: 75px;
    background:$bgWhite;
    box-shadow: $bgPrimaryWhiteShadow;
    transition: .5s ease all;
  position: relative;
.navbar-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    height: 75px;
    .logo{
        img{
            height: 50px;
        }
    }
    .right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    .avatar{
        img{
            height: 35px;
            border-radius: 50%;
            margin-right: 15px;
        }
    }
    .toggler-bar{
        margin-right: 15px;
        svg{
            font-size: 1.5rem;
            color: $primaryBG;
        }
    }
}

}

.menu-bar{
    width: 100%;
    padding: 25px 0;
    border-top: .1px solid $primaryBG;
    &.show{
        animation: .5s ease slideIn;
    }
}

}

    .main-overlay {
        background: #00000038;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
    }


@keyframes slideIn {
    from{
        transform:translateY(-100px)
    }
    to{
        transform:translateY(0)
    }
}