.left-side-bar{
    width:15%;
    background:#f7f7f7;
    box-shadow: $bgPrimaryWhiteShadow;
    position:relative;
    &.opened{
        display: none;
        width: 0%;
        opacity: 0;
        animation:.2s ease slideInRightCustom;
    }
    &.closed{
        display: block;
        width: 15%;
        opacity: 2;
        animation:.2s ease slideInLeftCustom;
       
    }
    .logo{
        width:100px;
        margin:50px auto;
        display:block;
        user-select: none;
    }

    .copyright{
        color:$textBlack;
        text-align:center;
        position:absolute;
        bottom:0;
        margin:auto;
        width:100%;
        p{
            color:$textBlack;
            font-size:0.7rem;
            font-weight: normal;
            user-select: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .left-side-bar{
        width:15%;
        .logo{
            width:35px;
            margin:25px auto;
        }
    
        .copyright{
           display: none;
        }
    }
}
@keyframes slideInLeftCustom {
    from{
        width: 0%;
    }
    to{
        width: 15%;
    }
}
@keyframes slideInRightCustom {
    from{
        width: 15%;
    }
    to{
        width: 0%;
    }
}