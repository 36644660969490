.category-icon {
    .icon-color {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        svg {
            color: white;
            font-size: 1rem;
        }
    }
}

.category-table{
    display: flex;
    align-items: center;
    b{
        margin-left:10px;
    }
}

.category-selector {
    .controller {
        display: flex;
        align-items: center;

        .control {
            cursor: pointer;

            svg {
                font-size: 1.5rem;
                opacity: 0.5;
            }

            &:hover {
                svg {
                    opacity: 1;
                }
            }
        }

        .category-color-picker {
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
            padding: 10px 0;
            line-height: 0;
            scroll-behavior: smooth;

            // display: flex;
            .color-circle {
                height: 30px;
                width: 30px;
                border-radius: 50px;
                margin-right: 10px;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                text-align: center;

                .selected {
                    height: 10px;
                    width: 10px;
                    background: white;
                    position: absolute;
                    border-radius: 50%;
                    left: 10px;
                    animation: .5s ease  selectColor;
                }
            }
        }


        .category-icon-picker {
            width: 100%;
            overflow-x: auto;
            white-space: nowrap;
            padding: 10px 0;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            scroll-behavior: smooth;

            .icon-circle {
                border-radius: 50px;
                margin-right: 10px;
                padding: 8px;
                animation:2s ease all;

                &.selected {
                    background-color: $primaryDarkShade;
                    animation: .5s ease  selectIcon;
                    
                    svg {
                        color: #fff;
                    }
                }

                svg {
                    font-size: 1.3rem;
                    height: 30px;
                    width: 30px;
                    color: #000;
                }
            }

            .friend-picker{
                background:$lightBG;
                border:2px solid $lightBG;
                margin-right: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 7px 25px ;
                border-radius: 50px;
                opacity: 0.6;
                width: min-content;
                img{
                    height:30px;
                    width: 30px;
                    border-radius: 50%;
                }
                span.title{
                    margin-left: 5px;
                    color: black;
                    user-select: none;
                }
                &.active{
                    background: white;
                    border:2px solid black;
                    opacity: 1;
                }
            }
        }

        .category-picker{
            background:$lightBG;
            border:2px solid $lightBG;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 15px ;
            border-radius: 50px;
            opacity: 0.6;
            span.title{
                margin-left: 5px;
                color: black;
                user-select: none;
            }
            &.active{
                background: white;
                border:2px solid black;
                opacity: 1;
            }
        }
       
    }

}

.type-picker{
    display: flex;
    width: max-content;
    margin-top: 5px;
    .type-pick{
        padding:10px 20px;
        background:$lightBG;
        margin-right: 10px;
        border-radius: 50px;
        position: relative;
        cursor: pointer;
        opacity: 0.4;
        &:hover{
            opacity: 1;
        }
        span{
            position: absolute;
            top: -7px;
            display: none;
            opacity: 0;
            right: 0;
           svg{
            background: green;
            border-radius: 50%;
            font-size: 1.5rem;
            color: white;
           }
        }
        &.expense{
            span{
                svg{
                    background:tomato;
                }
            }
        }
        &.active{
            opacity: 1;
            span{
                display: block;
                opacity: 1;
            }
        }
    }
}


@keyframes selectColor {
    from {
        width: 30px;
        height: 30px;
        left:0px;
    }

    to {
        width: 10px;
        height: 10px;
        left:10px
    }
}
@keyframes selectIcon {
    from {
      opacity: 0;
    }

    to {
       opacity: 1;
    }
}


@media only screen and (max-width: 768px) {
    .category-icon {
        .icon-color {
            height: 25px;
            width: 25px;
        }
    }
    
    .category-table{
        display: flex;
        align-items: center;
        b{
            margin-left:10px;
        }
    }
    
    .category-selector {
        margin-top:15px;
        .controller {
            .category-picker{
                border:1px solid $lightBG;
                padding: 5px 7px ;
                span.title{
                    font-size:0.7rem
                }
                &.active{
                    border:1px solid black;
                }
            }
           
        }
    
    }
    
.type-picker{
    margin-bottom: 15px;
    .type-pick{
        padding:7px 12px;
        font-size: 0.8rem;
        span{
            top: -7px;
            right: -3px;
           svg{
            font-size: 1rem;
           }
        }
    }
}
}