.menu-item{
    
    width:90%;
    margin:15px auto;
    border-radius:25px;
    color:$textBlack;
    cursor: pointer;
    transition: .2s ease all;
    &.mobile{
        margin:5px auto;
    }
    .menu-content{
        display: flex;
        padding:10px 15px;
        align-items: center;
        width: 90%;
        margin :auto;
    }
    .icon{
        svg{
            font-size:1.2rem;
        }
        margin-right:7px;
    }
    .menu-title{
        h3{
            margin-bottom: 0;
            font-size:1rem;
            font-weight: 400;
            line-height: 0;
        }
    }

    &:hover{
        color: $textBlackShade;
    }

    &.active{
        color: $primaryBG;
        background:#e1ebf0;
    }
}

@media only screen and (max-width: 768px) {
    // .menu-item{
    //     .menu-title{
    //         display: none;
    //     }
    // }
}